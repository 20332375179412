import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Certimail = lazy(() => import('sections/hero/Certimail.js'));
const PreciosCertimailSect = lazy(() => import('sections/services/PreciosCertimailSect.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const PreciosCertimailDigi = lazy(() => import('sections/services/PreciosCertimailDigi.js'));
const CitaCertimail = lazy(() => import('sections/hero/CitaCertimail.js'));
const EspecCertimailDigi = lazy(() => import('sections/services/EspecCertimailDigi.js'));
const BannerSSLsitelock = lazy(() => import('sections/hero/BannerSSLsitelock.js'));
const BotonSiteLock = lazy(() => import('sections/hero/BotonSiteLock.js'));
const SSLsanBlancoizq = lazy(() => import('sections/about/SSLsanBlancoizq.js'));
const SeguridadInformatica = lazy(() => import('sections/about/SeguridadInformatica.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelockSectigo.js'));
const EspecCertimailSect = lazy(() => import('sections/services/EspecCertimailSect.js'));
const FaqSiteLock = lazy(() => import('sections/services/FaqSiteLock.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>Certimail: Correo Electrónico certificado - Bogotá, Colombia</title>
          <meta name="description" content="Asegura la autenticidad, integridad y confidencialidad de tu correo electrónico con Certimail. Certificados que encriptan y firman de documentos electrónicos." />
        </Helmet>
        <Layout>
          <Certimail />           
          <PreciosCertimailSect />
          <PreciosCertimailDigi />
          <CitaCertimail />
          <EspecCertimailSect />          
          <EspecCertimailDigi />
          <BannerSSLsitelock />          
          <BotonSiteLock />          
          <SSLsanBlancoizq />
          <SeguridadInformatica />
          <SelloSitelock />          
          <FaqSiteLock />
          <BotonSanGeo />          
          <Llamenos />
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`